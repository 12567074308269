const artistsList = [
  {
    name: "Summer K",
    files: "summer",
    fullName: "Summer Kaay",
    location: "Kailua",
    pic: "https://sacredarthawaii.com/images/artists/summer.jpg",
    about:
      "hi hi I'm Summerkaay checkout my ig @beautybysummerkaay to see more of my work and what I have to offer !",
    instagram: "https://www.instagram.com/beautybysummerkaay",
    email: "",
    facebook: "",
    url: "/gallery/summer k",
  },
  {
    name: "Brian",
    fullName: "Brian Mau",
    files: "Brian",
    location: "Kailua",
    pic: "https://sacredarthawaii.com/images/artists/brian.jpg",
    about:
      "Brian Mau was born and raised on Oahu's east side in Kailua, where he developed an interest in tattoo culture in his teens, while hanging out in the shops of local artists. His career began in 2005, eventually co-owning a shop in California. After returning home to Hawaii, Brian joined his old friend Kevin Read shortly after Loyalty Tattoo opened. Though he enjoys doing color pieces the most, his talents include a variety of styles, big or small, gray tone or color, and styles range from American traditional to watercolor to fine-line mandalas and lettering. On Brian's days off you can find him on or in the water.",
    instagram: "https://www.instagram.com/Bmautattoo",
    email: "",
    facebook: "",
    url: "/gallery/brian",
  },
  {
    name: "Kevin",
    files: "Kevin",
    fullName: "Kevin Read",
    location: "Kailua",
    pic: "https://sacredarthawaii.com/images/artists/Kevin.jpeg",
    about:
      "Kevin Read was raised in Kailua, on the east side of Oahu. Kevin has always been a passionate artist, choosing art and illustration as his main outlet. His interest in tattoos started at a young age, as he spent his time hanging out in local tattoo parlors with his best friends. In 2000, Kevin began tattooing under the mentor-ship of tattoo artist, Christopher Ramsey and Ronnie Belton. He took over the shop in 2006, and renamed it to 'Sacred Art Tattoo', what it's known as today. Kevin enjoys all styles of tattooing and has a deep appreciation for its rich history. In 2012, he became a co-founder of the Pacific Ink and Art Expo in Hawaii, an annual event that takes place annually on the islands of Oahu and Maui.",
    instagram: "https://instagram.com/loyaltykevin",
    facebook: "https://www.facebook.com/Tat2kevin",
    email: "",
    url: "/gallery/kevin",
  },
  {
    name: "Kamaile",
    files: "kamaile",
    fullName: "Kamaile",
    location: "Kailua",
    pic: "https://sacredarthawaii.com/images/artists/Kamaile.jpg",
    about:
      "Aloha, I’m Kamaile. I was born and raised here on O’ahu in Waimānalo. Before piercing I worked with non-verbal adults with autism, child development and at-risk youth. Piercing was always an interest of mine and was even offered an apprenticeship after graduating high school but had a scholarship to play softball at a college in Washington state. During a burnout period, I decided on reaching out for an apprenticeship where Sacred Art Tattoo gave me an opportunity. I enjoy doing ear curations, and body piercings. When I'm not at the shop I like to go paddle boarding and spend time with my family and friends! Check out my Instagram to see more of my work!",
    instagram: "https://instagram.com/emotionholes",
    facebook: "",
    email: "",
    url: "/gallery/kamaile",
  },
  {
    name: "Julian",
    files: "Julian",
    fullName: "Julian Altier",
    location: "Kailua",
    pic: "https://sacredarthawaii.com/images/artists/Julian.jpeg",
    about:
      "Hello my name is Julian Altier and I'm a tattoo artist from So Cal. I have background in fine line and black and grey , but my passion and love is in Traditional tattoos. My take on it is a mixture of a few things: my graffiti background and Chicano upbringing with American tradition. I love to be given artistic freedom, free handing work , drawing straight onto the skin —it helps give a better flow when I can follow curves of the body .One of my goals is to bring more color to tattoos; to break themold of old traditional. Like bringing Afrocentric and Latin flavor to traditional pinups and art. I want to share my art and love for this craft to everyone —coloring the world one person at a time! For more examples of my work visit my Instagram page below!",
    instagram: "https://www.instagram.com/xwildxlovex",
    email: "",
    facebook: "",
    url: "/gallery/julian",
  },
  {
    name: "Mino",
    files: "Mino",
    fullName: "Mino Kaneko",
    location: "Kailua",
    pic: "https://sacredarthawaii.com/images/artists/mino.jpg",
    about:
      "Aloha! My name is Mino, a Marine with two Iraq deployments with 8.5 years of tattooing experience. First love of art is culinary. If I hadn’t signed up to be a Marine I would be in the kitchen. Hahaha, seriously, food! (Am I right?) I specialize in Polynesian tribal, geometric/mandala, fine line/minimalist work. I love bringing people’s ideas to fruition on skin.",
    instagram: "https://www.instagram.com/mino_tattoo",
    email: "",
    facebook: "https://www.facebook.com/minotattoo87/",
    url: "/gallery/mino",
  },
  {
    name: "Tait",
    files: "tait",
    fullName: "Tait Nelson",
    location: "Kailua",
    pic: "https://sacredarthawaii.com/images/artists/tait.jpg",
    about:
      "Aloha! My name is Tait! I'm born and raised on Oahu and I'm loving life tattooing at Sacred Art in Kailua!!! Outside of tattooing you can find me either surfing or with my dog doing dog dad things. I've been tattooing for a year and have quickly built a loyal following from doing fun tattoos, for a reasonable price all while we have a great time! I've been doing big bold bright tattoos built for speed as well as the light hearted and softer tattoos built for comfort haha. I love tattooing plain and simple so come hang out anytime! As Dominic Torretto would say, nothing matters except FAMILY. If you're in my chair we're family, especially if you let me do a Fast and the Furious tattoo:) P.s. I accept tacos for tips so please come feed me:)",
    instagram: "https://www.instagram.com/taiter_tatts",
    email: "",
    facebook: "",
    url: "/gallery/tait",
  },
  {
    name: "Alex",
    files: "12Alex",
    fullName: "Alex",
    location: "Kailua",
    pic: "https://sacredarthawaii.com/images/artists/alex2.jpg",
    about:
      "Hi! My name is Alex, I was born and raised in Kaneohe, and have loved art all my life. Growing up I was always drawing on myself, and for others whether it be nameplates, greeting cards, or portraits of family and friends. My love for all kinds of art is something that I want to share with the world, and play my part in creating a space in tattoos for women. I’m currently learning the ropes of the tattoo world, and also being trained to do any tattoo that walks through our doors. I want to strengthen my skill in illustrative and neo-traditional, you can find me in Instagram & Facebook: @AlickzTart for more!! I can’t wait to tattoo you!",
    instagram: "https://www.instagram.com/AlickzTart",
    email: "",
    facebook: "",
    url: "/gallery/alex",
  },
  {
    name: "Lexi",
    files: "Lexi",
    fullName: "Lexi",
    location: "Kailua",
    pic: "https://sacredarthawaii.com/images/artists/lexi.jpg",
    about:
      "Hey, my name is Lexi! I was born and raised in New York and spent my teen/adult life in Arizona before coming down to Oahu. Art has been my passion since the day I could read and write. I love kicking it with friends on my free time and exploring new things if I’m not just relaxing at the beach. I’ve been tattooing for 4 years and specialize in neotraditional tattoos but pretty much do everything in between! I love making sure my clients are taken care of and enjoy their time while getting tattooed and hope to do the same here on Oahu.",
    instagram: "https://www.instagram.com/morbidmusetattoos",
    email: "",
    facebook: "",
    url: "/gallery/lexi",
  },
];

export default artistsList;
